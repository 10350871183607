export const Colors = {
  black: '#141313',
  white: '#FFFFFF',

  grey100: '#F5F6FA',
  grey200: '#D8DCEA',
  grey300: '#BFC0C4',
  grey500: '#898A8D',
  grey600: '#747474',
  grey700: '#636365',
  grey900: '#3C3C3C',

  bluegrey100: '#F5F6FA',
  bluegrey300: '#E4E7F1',
  bluegrey500: '#C9CEE4',
  bluegrey700: '#AEB6D6',
  bluegrey900: '#929DC8',

  red100: 'rgba(246,92,89,0.2)',
  red500: '#F65C59',

  pink100: 'rgba(224,104,180,0.2)',
  pink500: '#E068B4',

  blue100: 'rgba(96,152,255,0.2)',
  blue500: '#6098FF',

  purple100: 'rgba(110,108,218,0.2)',
  purple500: '#6E6CDA',

  green100: 'rgba(111,207,151,0.2)',
  green500: '#6FCF97',

  yellow100: 'rgba(254,186,3,0.2)',
  yellow500: '#FEBA03',

  orange100: 'rgba(250,89,66,0.2)',
  orange500: '#FA5942',
};
